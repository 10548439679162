import Campain from "./Components/Campain";
import ContactUs from "./Components/ContactUs";
import FAQs from "./Components/FAQs";
import Footer from "./Components/Footer";
import Intro from "./Components/Intro";
import Navbar from "./Components/Navbar";
import Portfolio from "./Components/Portfolio";
import Services from "./Components/Services";
import Testimonials from "./Components/Testimonials";

function App() {
  return (
    <>
      <Navbar />
      <Intro />
      <Campain />
      <Services />
      <Testimonials />
      <FAQs />
      <Portfolio />
      <ContactUs />
      <Footer />

      <a href="#" className="shadow btn-primary rounded-circle back-to-top">
        <i className="fas fa-chevron-up" />
      </a>
    </>
  );
}

export default App;
