import ServiceImg1 from "./../../Assets/images/services/service-1.png";
import ServiceImg2 from "./../../Assets/images/services/service-2.png";
import ServiceImg3 from "./../../Assets/images/services/service-3.png";

const Services = () => {
  return (
    <section id="services" className="services">
      <div className="container">
        <div className="row text-center">
          <h1 className="display-3 fw-bold">Our Services</h1>
          <div className="heading-line mb-1" />
        </div>
        {/* START THE DESCRIPTION CONTENT  */}
        <div className="row pt-2 pb-2 mt-0 mb-3">
          <div className="col-md-6 border-right">
            <div className="bg-white p-3">
              <h2 className="fw-bold text-capitalize text-center">
                Our Services Range From Initial Design To Deployment Anywhere
                Anytime
              </h2>
            </div>
          </div>
          <div className="col-md-6">
            <div className="bg-white p-4 text-start">
              <p className="fw-light">
                AppyCraft provide comprehensive digital solutions, including
                website development, full-stack development, and mobile app
                development for Android and iOS. Our services are designed to
                enhance your online presence, create robust applications, and
                deliver custom software solutions tailored to your business
                needs. Partner with us to turn your ideas into powerful digital
                experiences.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* START THE CONTENT FOR THE SERVICES  */}
      <div className="container">
        {/* START THE MARKETING CONTENT  */}
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4">
            <div className="services__content">
              <div className="icon d-block fas fa-code" />
              <h3 className="display-3--title mt-1">Web & App Development</h3>
              <p className="lh-lg">
                At AppyCraft, we have successfully developed over 100
                applications and websites, earning us numerous happy client
                feedbacks. Our dedication to excellence ensures each project
                transforms ideas into powerful digital experiences. Join our
                satisfied clients and bring your vision to life with us.
              </p>
              <button
                type="button"
                className="rounded-pill btn-rounded border-primary"
              >
                Learn more
                <span>
                  <i className="fas fa-arrow-right" />
                </span>
              </button>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4 text-end">
            <div className="services__pic">
              <img
                src={ServiceImg1}
                alt="marketing illustration"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
        {/* START THE WEB DEVELOPMENT CONTENT  */}
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4 text-start">
            <div className="services__pic">
              <img
                src={ServiceImg2}
                alt="web development illustration"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4">
            <div className="services__content">
              <div className="icon d-block fas fa-paper-plane" />
              <h3 className="display-3--title mt-1">Scalable Projects</h3>
              <p className="lh-lg">
                At AppyCraft, we excel in developing scalable projects using
                Docker, Jenkins, GitHub, Bitbucket, and Jira. Our expertise
                ensures robust, efficient, and seamless integration and
                deployment. Experience the power of advanced tools with our
                reliable and secure solutions.
              </p>
              <button
                type="button"
                className="rounded-pill btn-rounded border-primary"
              >
                Learn more
                <span>
                  <i className="fas fa-arrow-right" />
                </span>
              </button>
            </div>
          </div>
        </div>
        {/* START THE CLOUD HOSTING CONTENT  */}
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4">
            <div className="services__content">
              <div className="icon d-block fas fa-cloud-upload-alt" />
              <h3 className="display-3--title mt-1">
                Monitoring & Cloud hosting
              </h3>
              <p className="lh-lg">
                At AppyCraft, we provide cloud hosting services using AWS, GCP,
                and Azure, ensuring reliable and scalable solutions. We also
                specialize in project monitoring with Firebase Analytics and
                CloudWatch to track user activity, offering insights for
                continuous improvement and optimal performance.
              </p>
              <button
                type="button"
                className="rounded-pill btn-rounded border-primary"
              >
                Learn more
                <span>
                  <i className="fas fa-arrow-right" />
                </span>
              </button>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4 text-end">
            <div className="services__pic">
              <img
                src={ServiceImg3}
                alt="cloud hosting illustration"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
