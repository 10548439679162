import SDS from "./../../Assets/images/portfolio/SDS-enclave-1.png";
import Mmart from "./../../Assets/images/portfolio/mmart gig-1.png";
import Dbudget from "./../../Assets/images/portfolio/dbudget gig-1.png";
import Tahadiyaat from "./../../Assets/images/portfolio/tahadiyaat gig-1.png";
import Mcircle from "./../../Assets/images/portfolio/Mcircle-1.png";
import Canvinn from "./../../Assets/images/portfolio/canwinn gig-1.png";
import Versatile from "./../../Assets/images/portfolio/Versatile-1.png";
import Metalfab from "./../../Assets/images/portfolio/metalfab-1.png";
import Robogyan from "./../../Assets/images/portfolio/Robogyan gig-1.png";

const Portfolio = () => {
  return (
    <section id="portfolio" className="portfolio">
      <div className="container">
        <div className="row text-center mt-5">
          <h1 className="display-3 fw-bold text-capitalize">Latest work</h1>
          <div className="heading-line" />
          <p className="lead">Discover Our Latest Projects in Development</p>
        </div>
        {/* FILTER BUTTONS  */}
        <div className="row mt-5 mb-4 g-3 text-center">
          <div className="col-md-12">
            <button className="btn btn-outline-primary" type="button">
              All
            </button>
            <button className="btn btn-outline-primary" type="button">
              websites
            </button>
            <button className="btn btn-outline-primary" type="button">
              design
            </button>
            <button className="btn btn-outline-primary" type="button">
              mockup
            </button>
          </div>
        </div>
        {/* START THE PORTFOLIO ITEMS  */}
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="portfolio-box shadow">
              <img
                src={SDS}
                alt="SDS Enclave, Real-state website"
                title="SDS Enclave"
                className="img-fluid"
              />
              <div className="portfolio-info">
                <div className="caption">
                  <h4>SDS Enclave</h4>
                  <p>Real State Website</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="portfolio-box shadow">
              <img
                src={Mmart}
                alt="M-mart, Ecommerce application"
                title="M-mart"
                className="img-fluid"
              />
              <div className="portfolio-info">
                <div className="caption">
                  <h4>M-Mart</h4>
                  <p>Ecommerce Application</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="portfolio-box shadow">
              <img
                src={Dbudget}
                alt="D-budget, Finance Application"
                title="D-budget"
                className="img-fluid"
              />
              <div className="portfolio-info">
                <div className="caption">
                  <h4>D-budget</h4>
                  <p>Finance Application</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="portfolio-box shadow">
              <img
                src={Tahadiyaat}
                alt="Tahadiyaat"
                title="Tahadiyaat"
                className="img-fluid"
              />
              <div className="portfolio-info">
                <div className="caption">
                  <h4>Tahadiyaat</h4>
                  <p>Sports Application</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="portfolio-box shadow">
              <img
                src={Mcircle}
                alt="Mcircle, Business Group"
                title="Mcircle"
                className="img-fluid"
              />
              <div className="portfolio-info">
                <div className="caption">
                  <h4>Mcircle</h4>
                  <p>Business Community Website</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="portfolio-box shadow">
              <img
                src={Canvinn}
                alt="Canwinn, Healthcare"
                title="Canwinn"
                className="img-fluid"
              />
              <div className="portfolio-info">
                <div className="caption">
                  <h4>Canwinn</h4>
                  <p>Healthcare Application</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="portfolio-box shadow">
              <img
                src={Versatile}
                alt="Versatile, Textile"
                title="Versatile textile Website"
                className="img-fluid"
              />
              <div className="portfolio-info">
                <div className="caption">
                  <h4>Versatile</h4>
                  <p>Textile Website</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="portfolio-box shadow">
              <img
                src={Metalfab}
                alt="Metalfab Corporation"
                title="Metalfab"
                className="img-fluid"
              />
              <div className="portfolio-info">
                <div className="caption">
                  <h4>Metalfab Corporation</h4>
                  <p>Business Wesite</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="portfolio-box shadow">
              <img
                src={Robogyan}
                alt="Robogyan App"
                title="Robogyan"
                className="img-fluid"
              />
              <div className="portfolio-info">
                <div className="caption">
                  <h4>Robogyan</h4>
                  <p>Educational App</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
