import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactUs = () => {
  const [contactValue, setContactValue] = useState({
    firstName: "",
    lastName: "",
    email: "",
    query: "",
    captcha: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactValue((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("first_name", contactValue.firstName);
    formData.append("last_name", contactValue.lastName);
    formData.append("email", contactValue.email);
    formData.append("query", contactValue.query);

    if (contactValue.captcha == "") {
      toast.error("Please verify recaptcha");
      return;
    }

    try {
      console.log(formData);
      const response = await fetch("https://localville.in/api/send_mail.php", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        toast.error("Something went wrong!");
        return;
      }

      const data = await response.json();
      console.log("Success:", data);
      setContactValue({
        firstName: "",
        lastName: "",
        email: "",
        query: "",
        captcha: "",
      });
      toast.success("We recieved your query, thanks for choosing us.");
      // Handle success (e.g., show a success message, clear the form, etc.)
    } catch (error) {
      toast.error("Something went wrong!");
      console.error("Error:", error);
      // Handle error (e.g., show an error message)
    }
  };
  return (
    <section id="contact" className="get-started">
      <div className="container">
        <div className="row text-center">
          <h1 className="display-3 fw-bold text-capitalize">Get started</h1>
          <div className="heading-line" />
          <p className="lh-lg">
            we’re eager to collaborate and deliver a high-quality, scalable
            solution tailored to your needs—let’s build something great
            together!
          </p>
        </div>
        {/* START THE CTA CONTENT  */}
        <div className="row text-white">
          <div className="col-12 col-lg-6 gradient shadow p-3">
            <div className="cta-info w-100">
              <h4 className="display-4 fw-bold">
                100% Satisfaction Guaranteed
              </h4>
              <p className="lh-lg">
                We’re excited to propose a collaboration on your upcoming
                project. Our team specializes in delivering high-quality,
                scalable solutions tailored to your business needs. Let’s build
                something great together!
              </p>
              <h3 className="display-3--brief">What will be the next step?</h3>
              <ul className="cta-info__list">
                <li>We'll prepare the proposal.</li>
                <li>we'll discuss it together.</li>
                <li>let's start the discussion.</li>
              </ul>
            </div>
          </div>
          <div className="col-12 col-lg-6 bg-white shadow p-3">
            <div className="form w-100 pb-2">
              <h4 className="display-3--title mb-5">start your project</h4>
              <form method="post" className="row" onSubmit={onSubmit}>
                <div className="col-lg-6 col-md mb-3">
                  <input
                    type="text"
                    placeholder="First Name"
                    id="inputFirstName"
                    name="firstName"
                    value={contactValue.firstName}
                    onChange={handleChange}
                    required
                    className="shadow form-control form-control-lg"
                  />
                </div>
                <div className="col-lg-6 col-md mb-3">
                  <input
                    type="text"
                    placeholder="Last Name"
                    id="inputLastName"
                    name="lastName"
                    value={contactValue.lastName}
                    onChange={handleChange}
                    required
                    className="shadow form-control form-control-lg"
                  />
                </div>
                <div className="col-lg-12 mb-3">
                  <input
                    type="email"
                    placeholder="email address"
                    id="inputEmail"
                    name="email"
                    value={contactValue.email}
                    onChange={handleChange}
                    required
                    className="shadow form-control form-control-lg"
                  />
                </div>
                <div className="col-lg-12 mb-3">
                  <textarea
                    name="query"
                    placeholder="message"
                    value={contactValue.query}
                    onChange={handleChange}
                    id="message"
                    rows={8}
                    className="shadow form-control form-control-lg"
                    defaultValue={""}
                    required
                  />
                </div>
                <div style={{ marginBottom: 20 }}>
                  <ReCAPTCHA
                    sitekey="6LcbLvopAAAAAFMkL-4YRmS4_lJcSzXd_x-HP8T6"
                    onChange={(item) => {
                      setContactValue((prevState) => ({
                        ...prevState,
                        captcha: item,
                      }));
                    }}
                  />
                </div>
                <div className="text-center d-grid mt-1">
                  <button
                    type="submit"
                    className="btn btn-primary rounded-pill pt-3 pb-3"
                  >
                    submit
                    <i className="fas fa-paper-plane" />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default ContactUs;
