const FAQs = () => {
  return (
    <section id="faq" className="faq">
      <div className="container">
        <div className="row text-center">
          <h1 className="display-3 fw-bold text-uppercase">faq</h1>
          <div className="heading-line" />
          <p className="lead">
            frequently asked questions, get knowledge befere hand
          </p>
        </div>
        {/* ACCORDION CONTENT  */}
        <div className="row mt-5">
          <div className="col-md-12">
            <div className="accordion" id="accordionExample">
              {/* ACCORDION ITEM 1 */}
              <div className="accordion-item shadow mb-3">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    How long does it take to develop a website?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    The timeline for website development varies based on the
                    project's complexity and requirements.
                    <strong>
                      On average, a standard website takes 4-6 weeks
                    </strong>{" "}
                    from initial consultation to launch. For more complex
                    projects, the timeline may extend.
                  </div>
                </div>
              </div>
              {/* ACCORDION ITEM 2 */}
              <div className="accordion-item shadow mb-3">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Do you provide ongoing maintenance and support?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Yes, we offer comprehensive maintenance and support services
                    to ensure your website or app runs smoothly post-launch. Our
                    support includes bug fixes, updates, security patches, and
                    general technical assistance.
                  </div>
                </div>
              </div>
              {/* ACCORDION ITEM 3 */}
              <div className="accordion-item shadow mb-3">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Can you help with SEO for my website?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Absolutely! Our digital marketing team specializes in SEO
                    strategies to improve your website's visibility on search
                    engines. We offer keyword optimization, content creation,
                    backlinking, and more to boost your online presence.
                  </div>
                </div>
              </div>
              {/* ACCORDION ITEM 4 */}
              <div className="accordion-item shadow mb-3">
                <h2 className="accordion-header" id="headingFour">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    How much does it cost to develop a website or app?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    The cost varies based on the complexity, features, and
                    technologies used in the project. We provide a detailed
                    quote after understanding your specific requirements.
                    Contact us for a free consultation and quote.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default FAQs;
