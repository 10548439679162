const Campain = () => {
  return (
    <section id="campanies" className="campanies">
      <div className="container">
        <div className="row text-center">
          <h4 className="fw-bold lead mb-3">We are developing with</h4>
          <div className="heading-line mb-5" />
        </div>
      </div>
      {/* START THE CAMPANIES CONTENT  */}
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-lg-2">
            <div className="campanies__logo-box shadow-sm">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOTGLpRQIfbiwGixMl9BWIePzWJN4yPc6kQA&s"
                alt="Campany 1 logo"
                title="Campany 1 Logo"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-md-4 col-lg-2">
            <div className="campanies__logo-box shadow-sm">
              <img
                src="https://quintagroup.com/cms/js/js-image/react.js-logo.png/@@images/a9bf22bd-373a-4fae-a900-c22fd12c87c7.png"
                alt="Campany 2 logo"
                title="Campany 2 Logo"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-md-4 col-lg-2">
            <div className="campanies__logo-box shadow-sm">
              <img
                src="https://1000logos.net/wp-content/uploads/2016/10/android-logo-wordmark.png"
                alt="Campany 3 logo"
                title="Campany 3 Logo"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-md-4 col-lg-2">
            <div className="campanies__logo-box shadow-sm">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7lspFz-80bPENBroDdyp4ZP5BYbeTXH8scw&s"
                alt="Campany 4 logo"
                title="Campany 4 Logo"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-md-4 col-lg-2">
            <div className="campanies__logo-box shadow-sm">
              <img
                src="https://cdn.magicbytesolutions.com/assets/img/common/ios-app.png"
                alt="Campany 5 logo"
                title="Campany 5 Logo"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-md-4 col-lg-2">
            <div className="campanies__logo-box shadow-sm">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/Logo.min.svg/2560px-Logo.min.svg.png"
                alt="Campany 6 logo"
                title="Campany 6 Logo"
                className="img-fluid"
              />
            </div>
          </div>

          {/* Second Row */}
          <div className="col-md-4 col-lg-2">
            <div className="campanies__logo-box shadow-sm">
              <img
                src="https://cdn.pixabay.com/photo/2015/04/23/17/41/node-js-736399_960_720.png"
                alt="Campany 6 logo"
                title="Campany 6 Logo"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-md-4 col-lg-2">
            <div className="campanies__logo-box shadow-sm">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/07/Angular_Logo_SVG.svg/2560px-Angular_Logo_SVG.svg.png"
                alt="Campany 6 logo"
                title="Campany 6 Logo"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-md-4 col-lg-2">
            <div className="campanies__logo-box shadow-sm">
              <img
                src="https://cdn.icon-icons.com/icons2/2699/PNG/512/vuejs_logo_icon_169247.png"
                alt="Campany 6 logo"
                title="Campany 6 Logo"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-md-4 col-lg-2">
            <div className="campanies__logo-box shadow-sm">
              <img
                src="https://inspector.dev/wp-content/uploads/2023/04/logo-python-django.png"
                alt="Campany 6 logo"
                title="Campany 6 Logo"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-md-4 col-lg-2">
            <div className="campanies__logo-box shadow-sm">
              <img
                src="https://static-00.iconduck.com/assets.00/nextjs-icon-2048x1234-pqycciiu.png"
                alt="Campany 6 logo"
                title="Campany 6 Logo"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-md-4 col-lg-2">
            <div className="campanies__logo-box shadow-sm">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8a/Jira_Logo.svg/2560px-Jira_Logo.svg.png"
                alt="Campany 6 logo"
                title="Campany 6 Logo"
                className="img-fluid"
              />
            </div>
          </div>

          {/* Third Row */}
          <div className="col-md-4 col-lg-2">
            <div className="campanies__logo-box shadow-sm">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/Amazon_Web_Services_Logo.svg/2560px-Amazon_Web_Services_Logo.svg.png"
                alt="Campany 6 logo"
                title="Campany 6 Logo"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-md-4 col-lg-2">
            <div className="campanies__logo-box shadow-sm">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a8/Microsoft_Azure_Logo.svg/2560px-Microsoft_Azure_Logo.svg.png"
                alt="Campany 6 logo"
                title="Campany 6 Logo"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-md-4 col-lg-2">
            <div className="campanies__logo-box shadow-sm">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/51/Google_Cloud_logo.svg/2560px-Google_Cloud_logo.svg.png"
                alt="Campany 6 logo"
                title="Campany 6 Logo"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-md-4 col-lg-2">
            <div className="campanies__logo-box shadow-sm">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/7/70/Docker_logo.png"
                alt="Campany 6 logo"
                title="Campany 6 Logo"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-md-4 col-lg-2">
            <div className="campanies__logo-box shadow-sm">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/67/Kubernetes_logo.svg/2560px-Kubernetes_logo.svg.png"
                alt="Campany 6 logo"
                title="Campany 6 Logo"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-md-4 col-lg-2">
            <div className="campanies__logo-box shadow-sm">
              <img
                src="https://pngimg.com/uploads/github/github_PNG23.png"
                alt="Campany 6 logo"
                title="Campany 6 Logo"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Campain;
